import React from "react";
import styled from "styled-components";

export default () => (
  <OuterWrapper>
    <PageWrapper>
      <h1>Page not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </PageWrapper>
  </OuterWrapper>
);

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 90vh;
  align-items: center;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 3.5rem;
    line-height: normal;
    margin: 0;
  }
  p {
    font-family: "Heebo", sans-serif;
    font-size: 1.6rem;
    font-weight: 100;
  }
`;
